<template>
  <p v-show="validForm" class="incorrect mb-4">{{ $t("incorrectAcount") }}</p>
  <form class="row g-3 d-flex flex-column" @submit.prevent="logIn">
    <!-- UserName -->
    <div>
      <label
        for="username"
        class="form-label"
        :class="{ incorrect: invalidUser }"
        >{{ $t("userName") }}</label
      >
      <input
        type="text"
        class="form-control input-text"
        id="username"
        v-model="username"
      />
      <div v-show="invalidUser" class="mt-2">
        {{ errorValueUsername }}
      </div>
    </div>

    <!-- Password -->
    <div>
      <label
        for="password"
        class="form-label"
        :class="{ incorrect: incorrectPass }"
        >{{ $t("password") }}</label
      >
      <div
        class="password position-relative"
        :class="{ rtl: $i18n.locale == 'ar' }"
      >
        <input
          :type="showPassword ? 'text' : 'password'"
          class="form-control input-text"
          id="password"
          :style="{ border: incorrectPass ? '1px solid #f73d17' : '' }"
          v-model="password"
        />
        <i
          v-if="showPassword"
          class="bi bi-eye position-absolute"
          :style="{ color: incorrectPass ? '#f73d17' : '' }"
          @click="showPassword = !showPassword"
          :class="{ rtl: $i18n.locale == 'ar' }"
        ></i>
        <i
          v-if="!showPassword"
          class="bi bi-eye-slash position-absolute"
          :style="{ color: incorrectPass ? '#f73d17' : '' }"
          @click="showPassword = !showPassword"
          :class="{ rtl: $i18n.locale == 'ar' }"
        ></i>
      </div>
      <div v-show="incorrectPass" class="mt-2">
        {{ errorValuePassword }}
      </div>
    </div>

    <div class="capatcha">{{ refresh }}</div>

    <div class="chars">
      <div class="d-flex gap-3">
        <input
          type="text"
          class="form-control input-text"
          :placeholder="$t('capatcha')"
          v-model="valueCapatch"
          :class="{ 'border-danger': captchValid }"
        />
        <div class="d-flex gap-3">
          <button
            class="btn btn-blue d-flex align-items-center"
            @click.prevent="findCapatch"
          >
            <span class="material-icons-outlined"> refresh </span>
          </button>
        </div>
      </div>
      <div v-show="captchValid" class="incorrect mt-2">
        {{ errorValueCapatch }}
      </div>
    </div>

    <div class="mt-0">
      <button class="btn btn-orange mt-2 mt-md-4 mb-1" type="submit">
        {{ $t("login") }}
      </button>
    </div>
  </form>
  <div v-if="loading" class="loading">
    <div class="text-center">
      <img src="@/assets/images/double.svg" alt="spinner" />
      <h3>Loading...</h3>
      <p>Please, Wait...</p>
    </div>
  </div>
</template>

<script>
// import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "LoginForm",
  data() {
    return {
      username: "ahm19ed",
      errorValueUsername: "",
      invalidUser: false,
      password: "1919Hh##",
      errorValuePassword: "",
      incorrectPass: false,
      showPassword: false,
      capatchList: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      capatch: "",
      valueCapatch: "",
      captchValid: false,
      errorValueCapatch: "",
      validForm: false,
      loading: false,
    };
  },
  methods: {
    // It is a function to randomly change the captcha code.
    findCapatch() {
      const l = this.capatchList.length - 1;
      this.capatch = "";
      this.captchValid = false;
      this.valueCapatch = "";
      for (let i = 0; i < 6; i++) {
        this.capatch += this.capatchList[Math.floor(Math.random() * l)];
      }
      return this.capatch;
    },
    async logIn() {
      if (!this.username) {
        this.invalidUser = true;
        this.errorValueUsername = this.$i18n.t("thisFieldIsMandatory");
      } else if (!this.password) {
        this.incorrectPass = true;
        this.errorValuePassword = this.$i18n.t("thisFieldIsMandatory");
      } else if (!this.valueCapatch) {
        this.captchValid = true;
        this.errorValueCapatch = this.$i18n.t("thisFieldIsMandatory");
      } else {
        this.loading = true;
        try {
          const res = await axios.post("dashboard/login", {
            username: this.username,
            password: this.password,
          });
          if (this.valueCapatch === this.capatch) {
            localStorage.setItem("topz_admin_id", res.data.data.id);
            localStorage.setItem("topz_admin_name", res.data.data.name);
            localStorage.setItem("topz_admin_token", res.data.data.token);
            localStorage.setItem(
              "topz_admin_permissions",
              JSON.stringify(res.data.data.permissions)
            );
            if (res.data.data.permissions.includes("home_page")) {
              this.$router.push(`/${this.$i18n.locale}`);
            } else {
              this.$router.push(
                `/${this.$i18n.locale}/${res.data.data.permissions[0]
                  .split("_")
                  .join("-")}`
              );
            }
          } else {
            this.loading = false;
            this.findCapatch();
            setTimeout(() => {
              this.captchValid = true;
              this.errorValueCapatch = this.$i18n.t("invalidChars");
            }, 0);
          }
        } catch (err) {
          this.findCapatch();
          this.validForm = true;
          this.loading = false;
          if (err.response.data.code == 403) {
            this.invalidUser = true;
            this.errorValueUsername = this.$i18n.t("invalidUser");
          } else if (
            err.response.data.code == 422 &&
            err.response.data.message != "Your Account Is Disabled" &&
            err.response.data.message != "لقد تم تعطيل حسابك"
          ) {
            this.incorrectPass = true;
            this.errorValuePassword = this.$i18n.t("invalidPass");
          } else {
            this.validForm = false;
            Swal.fire({
              icon: "error",
              title: this.$t("oops"),
              text: err.response.data.message,
            });
            // saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
            // alert("There Is A Problem In The Server, Please Try Again");
          }
        }
      }
    },
  },

  watch: {
    username() {
      this.validForm = false;
      this.invalidUser = false;
    },
    password() {
      this.validForm = false;
      this.incorrectPass = false;
    },
    valueCapatch() {
      this.captchValid = false;
    },
  },

  computed: {
    refresh() {
      return this.findCapatch();
    },
  },
};
</script>

<style scoped>
button {
  width: 100%;
}
label,
p {
  font-weight: 500;
  font-size: 18px;
}
form {
  width: 100%;
}
form > div {
  margin-top: 13px;
  margin-bottom: 13px;
}
@media (max-width: 575px) {
  form > div {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
form > div input {
  font-family: "Poppins", sans-serif;
}
form div.password input {
  padding-right: 50px;
}
form div.password.rtl input {
  padding-right: 12px;
  padding-left: 50px;
}
form div.password i {
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--black-alt);
  cursor: pointer;
  transition: var(--transition);
  font-size: 22px;
}
form div.password.rtl i {
  left: 22px;
  right: auto;
  line-height: 0;
}
form > div.password span:hover {
  color: var(--black);
}
.capatcha {
  background: var(--white);
  border-radius: 10px;
  min-height: 120px;
  text-align: center;
  line-height: 120px;
  font-size: 35px;
  letter-spacing: 10px;
  color: var(--black);
  font-family: "Poppins", sans-serif;
  user-select: none
}
.incorrect {
  color: #f73d17;
  font-weight: 500;
}
@media (max-width: 575px) {
  .capatcha {
    border-radius: 5px;
    min-height: 80px;
    line-height: 80px;
    font-size: 25px;
  }
  .incorrect {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  form div.password i {
    right: 15px;
    font-size: 17px;
  }
  form div.password.rtl i {
    left: 15px;
  }
}
</style>
