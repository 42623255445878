<template>
  <div class="modal-body">
    <!-- Category Title Arabic -->
    <div>
      <label for="titleAR" class="form-label">{{
        $t("categoryTitle") + "(" + $t("ar") + ")"
      }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="titleAR"
        dir="auto"
        :value="category.name_ar"
        @input="getNameAr"
        required
      />
    </div>

    <!-- Category Title English -->
    <div>
      <label for="titleEN" class="form-label">{{
        $t("categoryTitle") + "(" + $t("en") + ")"
      }}</label>
      <input
        type="text"
        class="form-control input-text font-poppins"
        id="titleEN"
        dir="auto"
        :value="category.name_en"
        @input="getNameEn"
        required
      />
    </div>

    <!-- Category Sort -->
    <div>
      <label for="sort" class="form-label">{{
        $t("listCategoryTable[2]")
      }}</label>
      <input
        type="number"
        min="1"
        class="form-control input-text font-poppins"
        id="sort"
        :value="category.sort"
        @input="getSort"
        required
      />
    </div>

    <!-- Category Icon -->
    <div class="edit-icon">
      <label class="form-label">{{ $t("icon") }}</label>
      <span v-if="iconSize" class="size-long">
        {{ $t("thisIconLargerThan") }}
      </span>
      <span v-if="iconDimension" class="size-long">
        {{ $t("iconDimensions") }}
      </span>
      <div class="d-flex align-items-center gap-3">
        <div class="image d-flex align-items-center justify-content-center">
          <img :src="category.icon" ref="icon" />
        </div>
        <div>
          <p ref="imageName">{{ iconName }}</p>
          <label for="editImageApp" class="upload">
            <span>{{ $t("editIcon") }}</span>
          </label>
          <input
            type="file"
            id="editImageApp"
            accept="image/*"
            @change="getImage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useFetchAll } from "@/stores/FetchAll";
import { mapState } from "pinia";

export default {
  name: "EditCategory",
  data() {
    return {
      titleAR: "",
      titleEN: "",
      sort: "",
      iconName: "icon.png",
      iconSize: false,
      iconDimension: false,
      file: null,
    };
  },
  computed: {
    ...mapState(useFetchAll, ["category"]),
  },
  methods: {
    getNameAr($event) {
      this.titleAR = $event.target.value;
    },
    getNameEn($event) {
      this.titleEN = $event.target.value;
    },
    getSort($event) {
      this.sort = $event.target.value;
    },
    getImage($event) {
      const reader = new FileReader();
      const image = new Image();
      const file = $event.target.files[0]
      if (!file) return;
      else {
        if (file.size > 55000) {
          this.iconSize = true;
        } else {
          this.file = file
          reader.onload = (e) => {
            image.src = e.target.result;
            image.onload = () => {
              if (image.width < 85 && image.height < 85) {
                this.iconDimension = false;
                // this.icon[0] = e.target.result;
                this.$refs.icon.src = e.target.result;
                this.iconName = file.name;
              } else {
                this.iconDimension = true;
              }
            };
          };
          reader.readAsDataURL(this.file);
          this.iconSize = false;
        }
      }

      $event.target.value = "";
    },
  },
};
</script>

<style scoped>
.modal-body .edit-icon .size-long {
  font-size: 12px;
  color: #f73d17 !important;
  margin-bottom: 5px;
}
.modal-body .edit-icon .image {
  background: var(--bg-color);
  width: 60px;
  height: 60px;
  border-radius: 5px;
  flex-shrink: 0;
}
.modal-body .edit-icon .image img {
  width: 36px;
  height: 36px;
  border-radius: 10px;
  object-fit: contain;
}
.modal-body .edit-icon p {
  font-size: 14px;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--black-alt);
  font-family: Poppins, sans-serif;
}
.modal-body .edit-icon p + label {
  margin-bottom: 0;
}
.modal-body label.upload span {
  width: 100%;
  font-size: 16px;
  color: var(--primary-orange);
  cursor: pointer;
}

@media (max-width: 575px) {
  .modal-body .edit-icon p {
    max-width: 280px;
    font-size: 12px;
  }
  .modal-body label.upload span {
    font-size: 12px;
  }
}
@media (max-width: 420px) {
  .modal-body .edit-icon p {
    max-width: 180px;
  }
}
</style>
