<template>
  <div class="user-info">
    <div class="d-flex flex-column flex-md-row">
      <div class="info">
        <p>
          <span>{{ $t("tableAdList[0]") }} : </span>
          {{ listInfo.id }}
        </p>
        <p class="category">
          <span>{{ $t("tableAdList[3]") }} : </span>
          <span>
            <span
              v-if="listInfo.category && listInfo.category.parent"
              class="me-1 ms-1"
            >
              <span v-if="listInfo.category.parent?.parent" class="me-1 ms-1">
                {{ listInfo.category.parent.parent?.name }}
                <i class="fa-solid fa-chevron-right"></i>
              </span>
              <span v-if="listInfo.category.parent" class="me-1 ms-1">
                {{ listInfo.category.parent.name }}
                <i class="fa-solid fa-chevron-right"></i>
              </span>
              <span class="me-1 ms-1">
                {{ listInfo.category.name }}
              </span>
            </span>
            <span v-else class="me-1 ms-1">
              <span class="me-1 ms-1"> {{ $t("no") }} </span>
            </span>
          </span>
        </p>
        <p>
          <span>{{ $t("tableAdList[4]") }} : </span>
          {{ listInfo.publisher_name.name }}
        </p>
        <p>
          <span>{{ $t("phone") }} : </span>
          <!-- <span class="phone-ltr">+966595111957</span> -->
          <span v-if="listInfo.publisher_name.phone_number" class="phone-ltr">
            +{{ listInfo.publisher_name.country.country_prefix
            }}{{ listInfo.publisher_name.phone_number }}
          </span>
          <span v-else>{{ $t("noPhone") }}</span>
        </p>
        <p>
          <span>{{ $t("tableAdList[1]") }} : </span>
          {{ listInfo.title }}
        </p>
      </div>
      <div class="info">
        <p>
          <span>{{ $t("title1publish[3]") }} : </span>
          {{ listInfo.views }}
        </p>
        <p>
          <span>{{ $t("tableAdList[7]") }} : </span>
          {{ listInfo.publisher_name.country.name }}
          <!-- {{ listInfo.publisher_name.city }} -->
        </p>
        <p>
          <span>{{ $t("tableAdList[10]") }} : </span>
          <span class="phone-ltr">{{ listInfo.publish_time }}</span>
        </p>
        <p>
          <span>{{ $t("allowComment") }} : </span>
          <span v-if="listInfo.allow_comment">{{ $t("yes") }}</span>
          <span v-else>{{ $t("no") }}</span>
        </p>
      </div>
    </div>
    <p class="description">
      <span class="d-block">{{ $t("desc") }} : </span>
      {{ listInfo.description.slice(5, -6) }}
    </p>
    <p class="youtube-link d-flex gap-2" v-if="listInfo.youtube_link">
      {{ $t("youtubeLink") }} :
      <a
        :href="listInfo.youtube_link"
        target="_blank"
        class="d-inline-block text-lowercase"
        >{{ listInfo.youtube_link }}</a
      >
    </p>
    <p v-else>{{ $t("youtubeLink") }} : {{ $t("noLink") }}</p>
    <div>
      <p>{{ $t("pictures") }} :</p>
      <div v-if="listInfo.media.length" class="pictures">
        <FancyBox
          class="aa d-flex flex-wrap gap-md-3 gap-2"
          :options="{
            Carousel: {
              infinite: true,
            },
          }"
        >
          <a
            v-for="img in listInfo.media"
            :key="img"
            :href="img"
            data-fancybox="gallery-a"
            class="video-box"
          >
            <img :src="img" />
          </a>
        </FancyBox>
        <!-- <span v-for="img in listInfo.media" :key="img">
          <img :src="img" />
        </span> -->
      </div>
      <div v-else class="pictures" :style="{ marginTop: '-15px' }">
        {{ $t("noPictures") }}
      </div>
    </div>
    <p>{{ $t("tableAdList[8]") }} :</p>
  </div>
</template>

<script>
import FancyBox from "../FancyBox.vue";

export default {
  name: "UserInfo",
  props: ["listInfo"],
  components: { FancyBox },
};
</script>

<style>
.fancybox__container {
  z-index: 1060;
}
.user-info {
  color: var(--black);
}
.user-info .info {
  flex-basis: 50%;
}
.user-info p {
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.user-info p.category i {
  font-size: 12px;
}
.user-info span {
  margin-right: 5px;
  display: inline-block;
}
.user-info .pictures {
  margin-bottom: 20px;
}
.user-info .pictures a {
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.user-info .pictures img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .user-info {
    font-size: 13px;
  }
  .user-info p {
    margin-bottom: 10px;
  }
  .user-info p.category i {
    font-size: 10px;
  }
  .user-info .pictures a {
    width: 55px;
    height: 55px;
  }
  .user-info .pictures img {
    border-radius: 7px;
  }
}

.description {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.youtube-link a {
  width: calc(100% - 200px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 575px) {
  .youtube-link a {
    width: calc(100% - 150px);
  }
}
</style>
